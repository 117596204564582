/*
 * toggle display of mobile menu and button on click
 */
const openbtn = document.querySelector("button.mobile-menu-button");
const closebtn = document.querySelector("#js-close-menu-icon");
const mobilemenu = document.querySelector(".mobile-menu");

openbtn.addEventListener("click", (e) => {
  mobilemenu.classList.remove("hidden");
});

closebtn.addEventListener("click", (e) => {
  mobilemenu.classList.add("hidden");
});

mobilemenu.addEventListener("click", (e) => {
  mobilemenu.classList.add("hidden");
});

/*
 * Scroll smoothly to on-page anchor link
 * Used on home page
 */

const links = document.querySelectorAll("#home nav ul a:not(.mobile)");

for (const link of links) {
  link.addEventListener("click", clickHandler);
}

function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth",
  });
}
